import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { message } from 'antd';

import { APP_VERSION } from '../config';
import store from '../store';
import { toggleLoading } from '../store/reducers/loading';
import token from '../utils/token';
import getErrMsg from './errMsg';

import { RequestConfig, BlobData } from './types';

const baseValidateStatus = (status: number) => {
    return (status >= 200 && status < 300) || status === 401;
};

/**
 * axios服务实例创建器
 * @param config axios配置
 * @param origin 是否返回原始结果，默认返回res.data
 */
export const create = (config: AxiosRequestConfig): { baseURL: string | undefined; api: AxiosInstance } => {
    const { baseURL, validateStatus } = config;

    config.validateStatus = validateStatus || baseValidateStatus;
    config.timeout = 30000;

    // == 创建实例
    const api = axios.create(config);

    // == 请求拦截
    api.interceptors.request.use((req: RequestConfig) => {
        if (req.loading) {
            store.dispatch(toggleLoading(true));
        }
        const headers: any = { ...req.headers };
        const _token = token.get();
        if (req.token && _token) {
            headers.Authorization = `bearer ${_token}`;
        }
        headers['APP-Version'] = APP_VERSION;
        req.headers = headers;
        if (req.url) {
            // 将参数值 null | undefined 转化为 空字符串
            req.url = req.url.replace(/(=)(undefined|null)(&)?/gi, (match, p1, p2, p3) => {
                return p1 + (p3 || '');
            });
        }

        return req;
    });

    // == 响应拦截
    api.interceptors.response.use(
        (res) => {
            if ((res.config as RequestConfig).loading) {
                store.dispatch(toggleLoading(false));
            }
            // == 拼接流数据返回体
            if (res.data instanceof Blob) {
                const match = res.headers['content-disposition']?.match(/filename=(.+)/);
                const blobData: BlobData = {
                    blob: res.data,
                    name: match ? decodeURI(match[1]) : '',
                };
                return {
                    code: 0,
                    data: blobData,
                    msg: '',
                };
            }
            if ([1008].includes(res.data.code)) {
                // token失效重新登陆
                token.remove();
                message.error(getErrMsg(res.data)).then(() => {
                    window.location.reload();
                });
                return Promise.reject(res.data);
            }
            if (res.data.code !== 0) {
                const msg = getErrMsg(res.data);
                if (msg) message.error(msg);
                return Promise.reject(res.data);
            }
            return res.data;
        },
        (err) => {
            if ((err.config as RequestConfig).loading) {
                store.dispatch(toggleLoading(false));
            }
            if (err && err.response) {
                if (err.response.status === 500) {
                    message.error('服务器异常, 请稍后再试');
                } else {
                    const res = err.response.data;
                    message.error(res.msg ? res.msg : res.error);
                }
            } else {
                message.error('网络异常, 请稍后再试');
            }
            return Promise.reject(err);
        },
    );

    return {
        baseURL,
        api,
    };
};

export default {
    create,
};
